import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        list: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('transports_min', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        shortList: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.get('transports_min', { params: {
                    "isShortHaul": 0
                } })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        regenerate: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('export_loads', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        routes: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('routes', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        warehouses: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('warehouses_micro', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
      
        route_dest: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.get(`route_dest/${id}?sourceType=0`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        show: (cntx, { id }) => {
            axios.get(`transports/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
        },
        create: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('transports', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        transportDriver: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('transdriver', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        find_route: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.post('find_route', params)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        update: (cntx, params) => {
            console.log(params)
            return new Promise((resolve, reject) => {
                axios.put(`transports/${params.id}`, params.data)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        remove: (cntx, id) => {
            return new Promise((resolve, reject) => {
                axios.delete(`transports/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        
        transdriver: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transdriver', { params: params })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        transporters: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('transporters', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        markets: (cntx, params) => {
            return new Promise((resolve, reject) => {
                axios.get('markets?isShortHaul=true', { params: params })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },

        
    }
}
