<template>
    <div>

        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModalTransport" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitTransports">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->

                <h5 v-if="long_haul.id === null">Add Transports</h5>
                <h5 v-if="long_haul.id !== null">Edit Transports</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreateFleet">
                        <b-row>
                            <b-col cols="6">

                                <b-form-group labe-for="Transporter" label="Transporter">
                                    <validation-provider #default="{ errors }" name="Transporter" rules="">
                                        <b-form-select size="md" v-model="long_haul.transporter_id"
                                            @change="changeFormSelection(long_haul.transporter_id)">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="transporter in transporters" :key="transporter.id"
                                                :value="transporter">
                                                {{ transporter.name.toUpperCase() }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.transporter_id" class="text-danger">{{
                                            serverErrors.transporter_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group labe-for="Drivers" label="Driver">
                                    <validation-provider #default="{ errors }" name="Driver" rules="">
                                        <b-form-select size="md" v-model="long_haul.driver_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="driver in transporterDrivers"
                                                :key="driver.person.id" :value="driver.id">
                                                {{ driver.person.firstName }} {{ driver.person.middleName }} {{
                                                    driver.person.lastName }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.driver_id" class="text-danger">{{
                                            serverErrors.driver_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="Fleets" label="Truck">
                                    <validation-provider #default="{ errors }" name="Fleet" rules="">
                                        <b-form-select size="md" v-model="long_haul.truck_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="fleet in fleets" :key="fleet.id" :value="fleet.id">
                                                {{ fleet.plateNumber }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.truck_id" class="text-danger">{{
                                            serverErrors.truck_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="Trailer" label="Trailer">
                                    <validation-provider #default="{ errors }" name="trailer" rules="">
                                        <b-form-select size="md" v-model="long_haul.trailer_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="trailer in trailers" :key="trailer.id"
                                                :value="trailer.id">
                                                {{ trailer.plateNumber }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.trailer_id" class="text-danger">{{
                                            serverErrors.trailer_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>

                        <b-row v-if="!long_haul.isShortHaul">
                            <b-col cols="6">
                                <b-form-group labe-for="source" label="Source">
                                    <validation-provider #default="{ errors }" name="source_id" rules="">
                                        <b-form-select size="md" v-model="long_haul.source_id"
                                            @change="changeSource(long_haul.source_id)">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                                :value="warehouse.id">
                                                {{ warehouse.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.source_id" class="text-danger">{{
                                            serverErrors.source_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group labe-for="routes" label="Destination">
                                    <validation-provider #default="{ errors }" name="Route" rules="">
                                        <b-form-select size="md" v-model="long_haul.destination_id"
                                            @change="findRoute(long_haul.source_id, long_haul.destination_id)">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="destination in destinations" :key="destination.id"
                                                :value="destination.id">
                                                {{ destination.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.destination_id" class="text-danger">{{
                                            serverErrors.destination_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="long_haul.isShortHaul">
                            <b-col cols="12">
                                <b-form-group labe-for="source" label="Destination">
                                    <validation-provider #default="{ errors }" name="source_id" rules="">
                                        <b-form-select size="md" v-model="long_haul.source_id">
                                            <b-form-select-option :value="null">-- Select --</b-form-select-option>
                                            <b-form-select-option v-for="society in markets" :key="society.id"
                                                :value="society.id">
                                                {{ society.name }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.source_id" class="text-danger">{{
                                            serverErrors.source_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- <b-row>
                            <b-col cols="12">
                                <b-form-group labe-for="orderNumber" label="Order Number">
                                    <validation-provider #default="{ errors }" name="orderNumber" rules="required">
                                        <b-form-input id="orderNumber" name="orderNumber" v-model="long_haul.orderNumber"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.orderNumber">{{
                                            serverErrors.orderNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>    
                        </b-row> -->


                        <b-row>
                            <b-col cols="6">
                                <b-form-group labe-for="orderNumber" label="Order Number">
                                    <validation-provider #default="{ errors }" name="orderNumber" rules="required">
                                        <b-form-input id="orderNumber" name="orderNumber" v-model="long_haul.orderNumber"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.orderNumber">{{
                                            serverErrors.orderNumber[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            
                            <b-col cols="6">
                                <b-form-group labe-for="number" label="Local TF number ">
                                    <validation-provider #default="{ errors }" name="number" rules="required">
                                        <b-form-input id="number" name="number" v-model="long_haul.number"
                                            :state="errors.length > 0 ? false : null" size="md" value="" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{
                                            serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                       
                        <b-row>
                            <b-col class="move-right">
                                <b-button size="sm" class="mb-1" variant="outline-primary"
                                    @click="addElement()">Add Seal</b-button>

                            </b-col>
                        </b-row>

                        <b-row>

                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Seal Number</th>
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  long_haul.seals" :key="index">
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="start" v-model="element.number" size="md" />

                                            </div>
                                        </td>
                                    
                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                </tbody>
                            </table>

                        </b-row>
                    </b-form>
                    <!-- <pre>{{ long_haul }}</pre> -->

                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Modal -->
        <b-modal ref="myModalTicket" centered no-close-on-backdrop no-close-on-esc :hide-footer=true size="lg">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Transports Tickets</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>


            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <b-row>
                    <b-col cols="4">
                        <label>Transportation type: <strong>{{ tfDetails.transaction.code }}</strong></label>
                    </b-col>
                    <b-col cols="4">
                        <label>TF Number: <strong>{{ tfDetails.tfNumber }}</strong></label>
                    </b-col>
                    <b-col cols="4">
                        <label>Source: <strong>{{ tfDetails.routes.sourceName }}</strong></label>
                    </b-col>
                </b-row>

                <b-row class="mb-10">
                    <b-col cols="4">
                        <label>Destination: <strong>{{ tfDetails.routes.destinationName }}</strong></label>
                    </b-col>
                    <b-col cols="4">
                        <label>Driver Licence: <strong>{{ tfDetails.drivers.licence }}</strong></label>
                    </b-col>
                    <b-col cols="4">
                        <label>Plate Number: <strong>{{ tfDetails.fleets[0].plateNumber }}</strong></label>
                    </b-col>
                </b-row>

                <div style="max-height: 400px; overflow-y: auto;">
                    <table class="table" style="margin-top: 20px">
                        <thead>
                            <tr>
                                <th scope="col">SN</th>
                                <th scope="col">Barcode</th>
                                <!-- <th scope="col">NGrade</th> -->
                                <th scope="col" v-if="tfDetails.transaction.code != 'OT'">R/Weight</th>
                                <th scope="col">D/Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(tiket, index)  in tikets">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>{{ tiket.barcode }}</td>
                                <!-- <td>{{ tiket.ngrade }}</td> -->
                                <td v-if="tfDetails.transaction.code != 'OT'">{{ tiket.massReceived }}</td>
                                <td >{{ tiket.massDispatched }}</td>
                            </tr>

                        </tbody>

                        <tfoot>
                            <tr style="font-weight:bold;">
                                <td class="footer">Total Bales:</td>
                                <td class="footer">{{ tikets.length }}</td>
                                <td class="footer">&nbsp;</td>
                                <td class="footer" v-if="tfDetails.transaction.code != 'OT'"><span v-if="totalWeight > 0">Total Mass:{{ totalWeight.toFixed(3) }}</span></td>
                                <td class="footer" v-if="tfDetails.transaction.code != 'RH'">{{ tValue.toFixed(3) }} </td>
                            </tr>
                        </tfoot>


                    </table>
                </div>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>

                        <b-button
                            variant="outline-success"
                            size="sm"
                            @click="generateReport()"
                            class="ml-2"
                        >
                            <b-spinner small class="ml-1" label="Small Spinner" v-if="saving" />
                            <span class="text-nowrap">Generate Data</span>
                            <feather-icon icon="DownloadCloudIcon" class="ml-1"/>
                        </b-button>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeFleetsForm()">
                                <span class="text-nowrap">Add Transports</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(check)="data">
                    <b-form-checkbox class="custom-control-success"
                                @change="checkboxToggled(data.item, $event)"
                                    :checked="isItemSelected(data.item.id)">
                    </b-form-checkbox>
                </template>

                <template #cell(transaction)="data">
                    {{ data.item.transaction.code }}
                </template>
                <template #cell(driver)="data">
                    {{ data.item.drivers.fullName }}
                </template>
                <template #cell(mobile)="data">
                    {{ data.item.drivers.mobile }}
                </template>

             

                <template #cell(fleets)="data">
                    <!-- {{ data.item.fleets[0].make }} - {{ data.item.fleets[0].plateNumber }} -->
                    <span v-if="data.item.fleets.length > 1">
                        {{ data.item.fleets[0].plateNumber }} \     {{ data.item.fleets[1].plateNumber }}
                    </span>
                    <span v-if="data.item.fleets.length == 1">
                        {{ data.item.fleets[0].plateNumber }}
                    </span>
                   
                </template>

                <template #cell(source)="data">
                    {{ data.item.routes.sourceName }}
                </template>

                <template #cell(destination)="data">
                    {{ data.item.routes.destinationName }}
                </template>
<!-- 
                <template #cell(tickets)="data">
                    {{ data.item.tickets.length }}
                </template> -->


                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="printPdf(data.item)">
                            <feather-icon icon="PrinterIcon" />
                            <span class="align-middle ml-50">Print</span>
                        </b-dropdown-item>
                        <!-- <b-dropdown-item @click="viewTickets(data.item)">
                            <feather-icon icon="EyeIcon" />
                            <span class="align-middle ml-50">View Tickets</span>
                        </b-dropdown-item> -->
                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import LongHaulsStoreModule from '@/views/cromis/green_shipping/long_haul/LongHaulsStoreModule'
import useLongHaulsList from '@/views/cromis/green_shipping/long_haul/useLongHaulsList'
import Dropdown from 'primevue/dropdown';
import axios from '@axios'
export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, Dropdown,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myModalTicket = ref(null)
        const myModalTransport = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const children = ref([])
        const transporters = ref([])
        const drivers = ref([])
        const transports = ref([])
        const transporterDrivers = ref([])
        const bestRoute = ref()
        const fleets = ref([])
        const trailers = ref([])
        const routes = ref([])
        const warehouses = ref([])
        const tfDetails = ref([])
        const markets = ref([])
        const tikets = ref([])
        const totalWeight = ref(0)
        const tValue = ref(0)
        const destinations = ref([])
        const selectedCompany = ref(null)
        const selectedDriver = ref();
        const  allChecked=  ref(false)
        const agreeIds = ref([])
        const selectedRows = ref([]);
        const selectedItems = ref([]);

        const transporter = ref({
            id: null,
            company_id: null,
            tin: null,
            name: null,
            vrn: null,
            busines_licence: null,
        })

        const long_haul = ref({
            id: null,
            truck_id: null,
            trailer_id: null,
            // route_id: null,
            source_id: null,
            gender: null,
            isShortHaul: null,
            transporter_id: null,
            orderNumber:null,
            seals: [
                {
                    number: null,
                }
            ]
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-long_haul'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, LongHaulsStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {

            await store.dispatch('cromis-long_haul/transporters')
                .then(response => {
                    transporters.value = response.data.transporters
                    // console.log(transporters.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            var str = window.location.href;
            var shotHaul;
            if (str[3] == "long_haul") {
                shotHaul = false
            } else {
                shotHaul = true
            }

            if (shotHaul) {
                transports.value = null;

                await store.dispatch('cromis-long_haul/shortList')
                    .then(response => {
                        transports.value = response.data.transports
                        // console.log(transports.value)
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

            } else {
                transports.value = null;

                await store.dispatch('cromis-long_haul/list')
                    .then(response => {
                        transports.value = response.data.transports
                        // console.log(transports.value)
                    })
                    .catch(error => {
                        console.log('got error', error)
                    })

            }

            await store.dispatch('cromis-long_haul/markets')
                .then(response => {
                    markets.value = response.data.markets
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-long_haul/routes')
                .then(response => {
                    routes.value = response.data.routes
                    // console.log(routes.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-long_haul/warehouses')
                .then(response => {
                    warehouses.value = response.data.warehouses
                    // console.log(warehouses.value)
                })
                .catch(error => {
                    console.log('got error', error)
                })

        })

        const toggleCheckAll = (isChecked) => {
    if (isChecked) {
        // Assuming `records` is an array of item objects
        selectedItems.value = records.value.map(item => item.id); // Replace 'id' with your unique identifier
    } else {
        selectedItems.value = [];
    }
}

        const isItemSelected = (itemId) => {
             return selectedItems.value.includes(itemId);
        };


        const checkboxToggled = async (item, event) => {

if (event) {
   if (!agreeIds.value.includes(item.id)) {
       agreeIds.value.push(item.id);
   }
} else {
   agreeIds.value = agreeIds.value.filter(id => id !== item.id);
}

};


        const changeFormSelection = async (transporter) => {
            // fleets.value = transporter.fleets

            transporter.fleets.forEach(ele => {
                if (ele.isTrailer == 0) {
                    fleets.value.push(ele)
                }
            });

            transporter.fleets.forEach(ele => {
                if (ele.isTrailer == 1) {
                    trailers.value.push(ele)
                }
            });

            await store.dispatch('cromis-long_haul/transportDriver', { transporter: transporter })
                .then(response => {
                    transporterDrivers.value = response.data

                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSource = async (source) => {
            await store.dispatch('cromis-long_haul/route_dest', source)
                .then(response => {
                    destinations.value = response.data.warehouses
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }
        const findRoute = async (source, dest) => {
            var route = {
                "source": source,
                "dest": dest
            }
            await store.dispatch('cromis-long_haul/find_route', { route: route })
                .then(response => {
                    bestRoute.value = response.data.id
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }



        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            locationFilter,
        } = useLongHaulsList()


        const viewTickets = (item) => {
            tfDetails.value = item
            tikets.value = item.tickets

            for (let index = 0; index < tikets.value.length; index++) {
                totalWeight.value += parseFloat(tikets.value[index].massReceived);
                tValue.value += parseFloat(tikets.value[index].massDispatched);

            }

            if (totalWeight.value == NaN) {
                totalWeight.value = 0.000
            }
            myModalTicket.value.show()
        }


        const addElement = () => {

long_haul.value.seals.push({ number: '' })

if (long_haul.value.seals.length > 0) {
    console.log(long_haul.value.seals)
}
}

const printPdf = async (tf) => {
          window.open(`${ axios.defaults.baseURL }/reports/print_transport?id=${tf.id }`, '_blank')
      }

const removeRow = (index) => {

if (long_haul.value.seals.length > 1) {
    long_haul.value.seals.splice(index, 1)
    long_haul.value.seals = long_haul.value.seals
}
}

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null

            const trans = transporters.value.find(obj => obj.id === item.drivers.transporter_id);
            changeFormSelection(trans)
            changeSource(item.routes.sourceId)
            long_haul.value = {
                id: item.id,
                transporter_id: trans,
                truck_id: item.truck_id,
                trailer_id: item.trailer_id,
                // route_id: item.routes.destinationId,
                driver_id: item.driver_id,
                source_id: item.routes.sourceId,
                destination_id: item.routes.destinationId,
                isShortHaul: false,
                orderNumber: item.orderNumber,
                seals:item.seals
            }
            myModalTransport.value.show()

        }

        const invokeFleetsForm = () => {
            serverErrors.value = null
            var str = window.location.href;
            var shotHaul;
            str = str.split("/");
            if (str[3] == "long_haul") {
                shotHaul = false
            } else {
                shotHaul = true
            }

            long_haul.value = {
                id: null,
                truck_id: null,
                trailer_id: null,
                route_id: 0,
                source_id: null,
                isShortHaul: shotHaul,
                transporter_id: null,
                orderNumber: null,
                seals: [
                {
                    number: null,
                }
            ]
            }
            myModalTransport.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })


            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (transporter.value.id === null || transporter.value.id === 0)
                handleCreate()
            else
                handleUpdate(sector.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }


        const submitTransports = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // handelDriverCreate()

            if (long_haul.value.id === null || long_haul.value.id === 0)
                handelDriverCreate()
            else
                handleUpdate(long_haul.value)

        }
        const handleUpdate = async (item) => {
            long_haul.value.transporter_id = ''
            saving.value = true
            const valid = await isFormValid()
            
            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-long_haul/update', { id: item.id, data: item })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false

                    myModalTransport.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to Transports has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })

        }

        const handelDriverCreate = async () => {

            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }
            long_haul.value.route_id = bestRoute

            // if (long_haul.value.isShortHaul == true) {
            //     long_haul.value.destination_id = long_haul.value.source_id
            //     long_haul.value.route_id = 0
            //     long_haul.value.source_id = null
            // }
            console.log(long_haul.value)


            await store.dispatch('cromis-long_haul/create', long_haul.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: 'The transport has been added successfully!',
                        showConfirmButton: true,
                        timer: 5000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                    long_haul.value = {
                        id: null,
                        license: null,
                        firstName: null,
                        lastName: null,
                        middleName: null,
                        gender: null,
                        transporter_id: null
                    }
                    myModalTransport.value.hide()
                })
                .catch(error => {
                    console.log(error)
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const generateReport = async () => {

        let payload = {
            "ids": agreeIds.value
        }
        await store.dispatch('cromis-long_haul/regenerate', payload)
            .then(response => {
                refetch()
                saving.value = false

                context.root.$swal({
                    icon: 'success',
                    text: `Regenerate File has been initialized successfully!`,
                    showConfirmButton: true,
                    timer: 3000,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            })
            .catch(error => {
                saving.value = false
                console.log(error)
                if (error.response.status === 422) {
                    serverErrors.value = error.response.data.errors
                }
                else {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: false,
                    })
                }
            })
    }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the this TF?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your TF is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }

        const remove = async (id) => {
            await store.dispatch('cromis-long_haul/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            transporter,
            long_haul,
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            changeFormSelection,
            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myModalTicket,
            myModalTransport,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            submitTransports,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            confirmDelete,
            transporters,
            transporterDrivers,
            drivers,
            fleets,
            routes,
            warehouses,
            changeSource,
            findRoute,
            selectedDriver,
            selectedCompany,
            destinations,
            transports,
            markets,
            invokeFleetsForm,
            tikets,
            totalWeight,
            tValue,
            viewTickets,
            tfDetails,
            trailers,
            addElement,
            removeRow,
            printPdf,
            isItemSelected,
            checkboxToggled,
            toggleCheckAll,
            generateReport 
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>